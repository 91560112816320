// Generated by Framer (1a6990e)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useConstant, useIsOnFramerCanvas, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["kKeMlcPLF", "FqVT2UF77", "rAY4wF3EG"];

const serializationHash = "framer-go05H"

const variantClassNames = {FqVT2UF77: "framer-v-1cszdw5", kKeMlcPLF: "framer-v-1pqw0sf", rAY4wF3EG: "framer-v-1rbibql"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {Eye: "FqVT2UF77", heart: "rAY4wF3EG", Insta: "kKeMlcPLF"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "kKeMlcPLF"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "kKeMlcPLF", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const isOnCanvas = useIsOnFramerCanvas()

const initialVariant = useConstant(() => variant)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1pqw0sf", className, classNames)} data-framer-name={"Insta"} initial={isOnCanvas ? variant : initialVariant } layoutDependency={layoutDependency} layoutId={"kKeMlcPLF"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "rgb(51, 51, 51)", borderBottomLeftRadius: 100, borderBottomRightRadius: 100, borderTopLeftRadius: 100, borderTopRightRadius: 100, ...style}} {...addPropertyOverrides({FqVT2UF77: {"data-framer-name": "Eye"}, rAY4wF3EG: {"data-framer-name": "heart"}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fill", intrinsicHeight: 16, intrinsicWidth: 16, pixelHeight: 16, pixelWidth: 16, src: "https://framerusercontent.com/images/jwe7zQcXtAQkzjrylHOG3VjaHTI.svg"}} className={"framer-35vpfm"} layoutDependency={layoutDependency} layoutId={"ehtW3XisG"} {...addPropertyOverrides({FqVT2UF77: {background: {alt: "", fit: "fill", intrinsicHeight: 18, intrinsicWidth: 18, pixelHeight: 18, pixelWidth: 18, src: "https://framerusercontent.com/images/BpAlQXYJPlPeYiebEnSJmQiAHGo.svg"}}, rAY4wF3EG: {background: {alt: "", fit: "fill", intrinsicHeight: 18, intrinsicWidth: 18, pixelHeight: 18, pixelWidth: 18, src: "https://framerusercontent.com/images/Gdnrh3TIWyLh7JetBXKnGz14k.svg"}}}, baseVariant, gestureVariant)}/></motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-go05H[data-border=\"true\"]::after, .framer-go05H [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-go05H.framer-1zaafv, .framer-go05H .framer-1zaafv { display: block; }", ".framer-go05H.framer-1pqw0sf { height: 36px; overflow: hidden; position: relative; width: 36px; will-change: var(--framer-will-change-override, transform); }", ".framer-go05H .framer-35vpfm { flex: none; height: 16px; left: calc(50.00000000000002% - 16px / 2); position: absolute; top: calc(50.00000000000002% - 16px / 2); width: 16px; }", ".framer-go05H.framer-v-1cszdw5.framer-1pqw0sf, .framer-go05H.framer-v-1rbibql.framer-1pqw0sf { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 36px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 36
 * @framerIntrinsicWidth 36
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"FqVT2UF77":{"layout":["fixed","fixed"]},"rAY4wF3EG":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const Framerbtx5PvGqF: React.ComponentType<Props> = withCSS(Component, css, "framer-go05H") as typeof Component;
export default Framerbtx5PvGqF;

Framerbtx5PvGqF.displayName = "social icon";

Framerbtx5PvGqF.defaultProps = {height: 36, width: 36};

addPropertyControls(Framerbtx5PvGqF, {variant: {options: ["kKeMlcPLF", "FqVT2UF77", "rAY4wF3EG"], optionTitles: ["Insta", "Eye", "heart"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(Framerbtx5PvGqF, [])